import React, { useEffect, useState } from "react";
import VBanner from "components/v-banner";
import VUpload from "components/v-upload";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { v4 as uuidv4 } from "uuid";

type IProps = {
  data?: any;
  setData: (e: any) => void;
};

const EditCover = (props: IProps, ref: any) => {
  const { data, setData } = props;

  const onChange = (value, name, field?: string, id?: string) => {
    if (field) {
      if (id) {
        setData((prev) => ({
          ...prev,
          covers: {
            ...prev.covers,
            banners: {
              ...prev.covers.banners,
              content: prev.covers.banners.content.map((el) =>
                el.id === id
                  ? {
                      ...el,
                      [name]: value,
                    }
                  : el
              ),
            },
          },
        }));
      } else {
        setData((prev) => ({
          ...prev,
          covers: {
            ...prev.covers,
            [field]: {
              ...prev.covers[field],
              [name]: value,
            },
          },
        }));
      }
    } else {
      setData((prev) => ({
        ...prev,
        covers: {
          ...prev.covers,
          [name]: value,
        },
      }));
    }
  };

  const handleAdd = () => {
    setData((prev) => ({
      ...prev,
      covers: {
        ...prev.covers,
        banners: {
          ...prev.covers.banners,
          content: prev.covers.banners.content.concat({
            id: uuidv4(),
            icon: "",
            value: "",
            link: "",
          }),
        },
      },
    }));
  };

  const handleSub = () => {
    setData((prev) => ({
      ...prev,
      covers: {
        ...prev.covers,
        banners: {
          ...prev.covers.banners,
          content: prev.covers.banners.content.slice(
            0,
            prev.covers.banners.content.length - 1
          ),
        },
      },
    }));
  };

  const handleUpload = (file: any, id: string) => {
    // const _slide = data.banners.content.map((el) => {
    //   if (el.id === id) return { ...el, icon: file };
    //   else return { ...el };
    // });
    setData((prev) => ({
      ...prev,
      covers: {
        ...prev.covers,
        banners: {
          ...prev.covers.banners,
          content: prev.covers.banners.content.map((el) =>
            el.id === id
              ? {
                  ...el,
                  icon: file,
                }
              : el
          ),
        },
      },
    }));
  };

  return (
    <div className="grid">
      <div className="field cold-12">
        <VBanner
          type="cover"
          data={{
            ...data,
            images: data.slide,
            facebook: data.url,
            type: data.type,
          }}
          onChange={onChange}
        />
      </div>
      <div className="field col-12">
        <label htmlFor="cover_banners">Cover banners</label>
        <div className="field col-12 grid">
          <div className="field col-5">
            <InputText
              placeholder={"Text color"}
              id="text-color"
              value={data?.banners?.text_color}
              onChange={(e) =>
                onChange(e.target.value, "text_color", "banners")
              }
              required
              autoFocus
            />
          </div>
          <div className="field col-1">
            <input
              type={"color"}
              value={data?.banners?.text_color}
              onChange={(e) =>
                onChange(e.target.value, "text_color", "banners")
              }
            />
          </div>
          <div className="field col-5">
            <InputText
              placeholder={"Background color"}
              id="text-color"
              value={data?.banners?.background_color}
              onChange={(e) =>
                onChange(e.target.value, "background_color", "banners")
              }
              required
              autoFocus
            />
          </div>
          <div className="field col-1">
            <input
              type={"color"}
              value={data?.banners?.background_color}
              onChange={(e) =>
                onChange(e.target.value, "background_color", "banners")
              }
            />
          </div>
          <div className="field col-12">
            <Button
              icon="bx bxs-add-to-queue"
              className="p-button-rounded p-button-success"
              aria-label="Search"
              onClick={(_) => handleAdd()}
            />
            <Button
              icon="bx bxs-minus-circle"
              className="p-button-rounded p-button-danger"
              aria-label="Cancel"
              onClick={(_) => handleSub()}
            />
          </div>
          {data.banners.content.map((ct) => (
            <div className="field col-12 grid" key={ct.id}>
              <div className="field col-2">
                <VUpload
                  urlFile={ct.icon}
                  setUrlFile={(file) => handleUpload(file, ct.id)}
                />
              </div>
              <div className="field col-10 grid">
                <div className="field col-12">
                  <InputText
                    placeholder="Title"
                    value={ct.value}
                    onChange={(e) =>
                      onChange(e.target.value, "value", "banners", ct.id)
                    }
                  />
                </div>
                <div className="field col-12">
                  <InputText
                    placeholder="Link"
                    value={ct.link}
                    onChange={(e) =>
                      onChange(e.target.value, "link", "banners", ct.id)
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EditCover;
