/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact";
import { InputTextarea } from "primereact/inputtextarea";
import VirtualPostActionsService from "services/virtual-post-actions";
import { Calendar } from "primereact/calendar";
import { POST_TYPE } from "utils/enum";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    post_type: "normal",
    status: "enable",
    content: "",
    start_date: "",
    end_date: "",
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails({
        ...details,
        ...data,
      });
    }
  }, [data]);

  const onChange = (name, value, field?) => {
    if (field) {
      setDetails((prev) => ({
        ...prev,
        [field]: {
          ...prev[field],
          [name]: value === "-1" ? null : value,
        },
      }));
    }
    setDetails((prev) => ({
      ...prev,
      [`${name}`]: value === "-1" ? null : value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      await VirtualPostActionsService.create({
        body: details,
      });
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      {/* <div className="field col-12 md:col-6">
        <label htmlFor="type">Type</label>
        <Dropdown
          filter
          value={details.type}
          options={[
            {
              name: "Feel",
              key: "feel",
            },
            {
              name: "Badge",
              key: "badge",
            },
          ]}
          optionLabel="name"
          optionValue="key"
          onChange={(e) => onChange("type", e.value)}
        />
      </div> */}
      <div className="field col-12 md:col-6">
        <label htmlFor="post_type">Post type</label>
        <Dropdown
          filter
          value={details.post_type}
          options={Object.keys(POST_TYPE).map((el) => ({
            name: el,
            key: POST_TYPE[el],
          }))}
          optionLabel="name"
          optionValue="key"
          onChange={(e) => onChange("post_type", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="status">Status</label>
        <Dropdown
          value={details.status}
          options={[
            {
              name: "Enable",
              key: "enable",
            },
            {
              name: "Disable",
              key: "disable",
            },
          ]}
          optionLabel="name"
          optionValue="key"
          onChange={(e) => onChange("status", e.value)}
        />
      </div>
      <div className="col-12 md:col-6">
        <Calendar
          className="m-1"
          id="time24"
          placeholder="Start Date"
          value={new Date(details.start_date)}
          onChange={(e: any) => onChange("start_date", e.value)}
          showTime
          showSeconds
          showButtonBar
        />
      </div>
      <div className="col-12 md:col-6">
        <Calendar
          className="m-1"
          id="time24"
          placeholder="End Date"
          value={new Date(details.end_date)}
          onChange={(e: any) => onChange("end_date", e.value)}
          showTime
          showSeconds
          showButtonBar
        />
      </div>
      <div className="field col-12">
        <label htmlFor="content">Content</label>
        <InputTextarea
          id="content"
          value={details.content}
          onChange={(e) => onChange("content", e.target.value)}
          required
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
