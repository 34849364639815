import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/virtual-post-actions/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

  const create = (req: { body: any }) =>
    new Promise((resolve, reject) => {
      request()
        .post(`admin/virtual-post-actions/create`, req.body)
        .then((res) => {
          const { data } = res.data;
          if (data) resolve(data);
          else reject(new Error("Something went error!"));
        })
        .catch(parseErrorResponse)
        .then(reject);
    });

const deleted = (req: { params: { id: string }}) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/virtual-post-actions/deleted/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const VirtualPostActionsService = {
  search,
  create,
  deleted,
};

export default VirtualPostActionsService;
