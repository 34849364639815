/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, getUrlImage, showToast } from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import Details from "./components/Details";
import debounce from "lodash.debounce";
import { MENU_STATUS, POST_TYPE } from "utils/enum";
import { Dropdown } from "primereact/dropdown";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import { Panel } from "primereact/panel";
import { Calendar } from "primereact/calendar";
import VirtualPostActionsService from "services/virtual-post-actions";
import { DataTable } from "primereact/datatable";

const Crud = () => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: null,
    post_type: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "createdAt",
    sortOrder: -1,
  });
  console.log({ lazyParams });

  const [totalRecords, setTotalRecords] = useState(0);
  const [menusCategory, setMenusCategory] = useState([]);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await VirtualPostActionsService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          limit: 10,
        },
      });
      if (res) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else {
        setData([]);
      }
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event, page: event.page + 1 };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetail({ ...data, key: data.keyVal });
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (action) => {
    setDetail(action);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await VirtualPostActionsService.deleted({
        params: {
          id: detail._id,
        },
      });
      loadData();
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Action Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeStatus = async (options, rowData, value) => {
    let updateRow;
    let newData = data.map((e, index) => {
      if (e._id === rowData?._id) {
        e[options.field] = value;
        updateRow = e;
      }
      return e;
    });
    setData(newData);

    if (updateRow) {
      await VirtualPostActionsService.create({
        body: {
          ...updateRow,
        },
      });
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-4">
          <span className="block p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </span>{" "}
        </div>
        <div className="col-12 md:col-4">
          <Calendar
            className="m-1"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
        <div className="col-12 md:col-4">
          <Calendar
            className="m-1"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
        <div className="col-12 md:col-4">
          <Dropdown
            value={globalFilter.status}
            options={Object.keys(MENU_STATUS).map((k) => ({
              label: k,
              value: MENU_STATUS[k],
            }))}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="label"
            placeholder="Status"
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-4">
          <Dropdown
            value={globalFilter.post_type}
            options={Object.keys(POST_TYPE).map((k) => ({
              label: k,
              value: POST_TYPE[k],
            }))}
            onChange={(e: any) => onSearch("post_type", e.value)}
            optionLabel="label"
            placeholder="Post type"
            className={"w-full"}
            showClear
          />
        </div>
      </div>
    </Panel>
  );
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              frozen
              alignFrozen="left"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "160px" }}
            ></Column>
            <Column
              field="post_type"
              header="Post type"
              style={{ flexGrow: 1, flexBasis: "100px" }}
            ></Column>
            <Column
              field="content"
              header="Content"
              style={{ flexGrow: 1, flexBasis: "300px" }}
              body={(rowData) => String(rowData.content || "").slice(0, 30)}
            ></Column>
            <Column
              // editor={statusEditor}
              style={{ flexGrow: 1, flexBasis: "180px" }}
              field="status"
              header="Status"
              body={(rowData, options) => (
                <StatusEditorCell
                  value={rowData.status}
                  options={options}
                  rowData={rowData}
                  onChange={onChangeStatus}
                />
              )}
            ></Column>
             <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="start_date"
              header="Start date"
              sortable
              body={(rowData) => formatLocalTime(rowData.start_date)}
            ></Column>
             <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="end_date"
              header="End date"
              sortable
              body={(rowData) => formatLocalTime(rowData.end_date)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Menu Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              menusCategory={menusCategory}
              setMenusCategory={setMenusCategory}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const iconBody = (rowData: any) => {
  return (
    <>
      {rowData.img_icon ? (
        <div>
          <img
            src={`${getUrlImage(rowData.img_icon)}`}
            alt={rowData.img_icon}
            style={{ width: "20px" }}
          />
        </div>
      ) : (
        <React.Fragment>
          <i style={{ textAlign: "center" }} className={rowData.icon}>
            &nbsp;
          </i>
        </React.Fragment>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
