import VUpload from "components/v-upload";
import { Dropdown, InputText } from "primereact";
import React from "react";
import SlideUpload from "./components/SlideUpload";

const BANNER_TYPE = [
  {
    label: "Slide",
    value: "slide",
  },
  {
    label: "Video",
    value: "video",
  },
  {
    label: "Youtube",
    value: "youtube",
  },
  {
    label: "Link",
    value: "link",
  },
];

type IProps = {
  disable?: boolean;
  data?: any;
  onChange?: any;
  type?: string;
};

const VBanner = ({ disable, data, onChange, type }: IProps) => {
  return (
    <div className={`${disable && "pointer-events-none"} grid col-12 w-full`}>
      <div className="field col-12 w-full">
        <label htmlFor="type">Type </label>
        <Dropdown
          className="w-full"
          value={data?.type}
          options={BANNER_TYPE}
          onChange={(e) =>
            onChange(e.value, "type", type === "cover" ? null : "banner")
          }
          optionLabel="label"
          filter
          placeholder="Select type"
        />
      </div>
      {data?.type === "slide" && (
        <>
          <div className="field col-12">
            <SlideUpload
              slide={data?.images}
              setSlide={(slide) =>
                onChange(
                  slide,
                  type === "cover" ? "slide" : "images",
                  type === "cover" ? null : "banner"
                )
              }
            />
          </div>
        </>
      )}
      {data?.type === "video" && (
        <div className="field col-12">
          <VUpload
            urlFile={data?.video || data?.url}
            fileType="video"
            setUrlFile={(file) =>
              onChange(
                file,
                type === "cover" ? "url" : "video",
                type === "cover" ? null : "banner"
              )
            }
          />
          <InputText
            value={data?.link_href_video}
            placeholder="Link..."
            onChange={(e) =>
              onChange(e.target.value, "link_href_video", "banner")
            }
          />
        </div>
      )}
      {(data?.type === "link" ||
        data?.type === "youtube" ||
        data?.type === "facebook") && (
        <div className="field col-12">
          <label htmlFor="link">Link</label>
          <InputText
            placeholder={data?.type}
            id="banner"
            value={data?.video || data?.url}
            onChange={(e) =>
              onChange(
                e.target.value,
                type === "cover" ? "url" : "video",
                type === "cover" ? null : "banner"
              )
            }
            required
            autoFocus
          />
        </div>
      )}
    </div>
  );
};

export default VBanner;
